<template>
  <div>
    <section class="section">
      <div class="container mt-60">
        <div class="row">
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4">
            <library :content.sync="content" />
          </div>
          <div class="col-xl-9 col-lg-8 col-md-8 col-sm-8">
            <div class="section-title">
              <div class="media features">
                <div class="icons m-0 rounded h1 text-primary px-3">
                  <i class="uil uil-users-alt"></i>
                </div>
              </div>
              <h4 class="title">เมนูจัดการข้อมูลผู้ใช้งาน</h4>
              <p class="border-bottom"></p>
              <h5 class="text-primary">วิธีสร้างบัญชีผู้ใช้งาน</h5>
            </div>

            <div class="row justify-content-center">
              <div class="col-12">
                <b-tabs pills justified content-class="mt-4 pt-2">
                  <b-tab>
                    <template v-slot:title>
                      <div class="text-center pt-1 pb-1">
                        <h4 class="title font-weight-normal mb-0">
                          สร้างบัญชีผู้ใช้งานใหม่
                        </h4>
                      </div>
                    </template>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">1</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">กดปุ่ม "Add User"</h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal1>
                          <img
                            src="/images/screen/userManagement/addUser1.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal1"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/userManagement/addUser1.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">2</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              ให้ผู้ใช้กรอกข้อมูลในแบบฟอร์มเพิ่มผู้ใช้ใหม่
                              และกดปุ่มบันทึก
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal2>
                          <img
                            src="/images/screen/userManagement/addUser2.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal2"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/userManagement/addUser2.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab>
                    <template v-slot:title>
                      <div class="text-center pt-1 pb-1">
                        <h4 class="title font-weight-normal mb-0">
                          นำเข้าผ่านไฟล์ Excel
                        </h4>
                      </div>
                    </template>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">1</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">กดปุ่ม "Import Users"</h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal3>
                          <img
                            src="/images/screen/userManagement/importUser1.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal3"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/userManagement/importUser1.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">2</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              เลือกประเภทที่ต้องการนำเข้าข้อมูล
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal4>
                          <img
                            src="/images/screen/userManagement/importUser2.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal4"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/userManagement/importUser2.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">3</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              ให้ผู้ใช้ดาวน์โหลดไฟล์ตัวอย่างการนำเข้าข้อมูลผู้ใช้โดยการกดปุ่ม
                              "Download Sample"
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal5>
                          <img
                            src="/images/screen/userManagement/importUser3.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal5"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/userManagement/importUser3.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">4</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              คัดลอกไอดีของกลุ่มผู้ใช้งานแล้วนำไปใส่ที่คอลัมน์
                              usergroups ในไฟล์ตัวอย่าง
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal6>
                          <img
                            src="/images/screen/userManagement/importUser4.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal6"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/userManagement/importUser4.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">5</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              นำไฟล์ที่เตรียมข้อมูลเรียบร้อยแล้วเพิ่มมายังระบบโดยการกดที่
                              "Drag a file and place it here OR click here to
                              choose file"
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal7>
                          <img
                            src="/images/screen/userManagement/importUser5.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal7"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/userManagement/importUser5.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">6</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              ตรวจสอบความถูกต้อง และกดปุ่ม "Confirm Importing"
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal8>
                          <img
                            src="/images/screen/userManagement/importUser6.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal8"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/userManagement/importUser6.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import library from "@/views/library/library.vue";
export default {
  data() {
    return {
      content: "userCreate",
    };
  },
  components: {
    library,
  },
};
</script>

